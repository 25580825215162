.list-group {
    display: contents;
}
.footer_background {
    margin-top: 2rem;
    border-top: 1px solid lightgrey
}
.footer_block {
    align-items: center;
    margin-top: 3rem;
}
.list-group-item {
    padding: 0px;
    border: none;
    font-size: 17px;
    font-weight: 400;
    position: relative;
}
p.footertext {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.list_1::after {
    position: absolute;
    content:"" ;
    border-bottom: 2px solid #000000;
    left: 1px;
    bottom: 0px;
}
.list_1:hover::after {
    width: 20%;
   
}

@media (max-width: 769px) { 
   .block_1 {
    margin-top: 1rem;
   }
}