body {
    /* padding-top: 5rem; */
    /* padding-bottom: 3rem; */
    
    color: #5a5a5a;
  }   

  .Block2paragraph {
    margin-top: 0rem;
  }
  .CommonTop3 {
    margin-top: 3rem;
  }
  .CommonFont {
    font-size: 30px;
    font-weight: 400;
  }
  .AlignItemCenter {
    align-items: center;
    padding: 2rem 0 0 0 ;
  }  
  .color_text {
    color: black;
  }

  .h1A {
    text-decoration: none;
    color: black !important;
  }

  .Homepage_Ani {
    position: relative;
    animation-name: HomepageAni;
    animation-duration: 2s;
  }
  .Homepage_Ani_1 {
    position: relative;
    animation-name: HomepageAni1;
    animation-duration: 2s;
  }
  @keyframes HomepageAni {
    /* 0%   {left:0px; top:0px;} */
    25%  { left:400px; top:0px;}
    100% {left:0px; top:0px;}
  }
  @keyframes HomepageAni1 {
    /* 0%   {left:0px; top:0px;} */
    25%  { right:400px; top:0px;}
    100% {right:0px; top:0px;}
  }

  @media (min-width: 40em) {
    .featurette-heading {
      font-size: 50px;
    }
  }
  
  @media (min-width: 62em) {
    .featurette-heading {
      /* margin-top: 7rem; */
    }
  }
   @media (max-width: 576px) { 
    .CommonFont {
        font-size: 20px;
        font-weight: 400;
      }
    .Block2paragraph {
        margin-top: 1rem;
    }
  }

  .feature-icon {
    width: 4rem;
    height: 4rem;
    border-radius: .75rem;
  }
  
  .icon-link > .bi {
    margin-top: .125rem;
    margin-left: .125rem;
    fill: currentcolor;
    transition: transform .25s ease-in-out;
  }
  .icon-link:hover > .bi {
    transform: translate(.25rem);
  }
  
  .icon-square {
    width: 3rem;
    height: 3rem;
    border-radius: .75rem;
  }
  
  .text-shadow-1 { text-shadow: 0 .125rem .25rem rgba(0, 0, 0, .25); }
  .text-shadow-2 { text-shadow: 0 .25rem .5rem rgba(0, 0, 0, .25); }
  .text-shadow-3 { text-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .25); }
  
  .card-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  
  .feature-icon-small {
    width: 3rem;
    height: 3rem;
  }

