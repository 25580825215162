.justify-content-center {
    display: flex;
    justify-content: center;
}

.text-center {
    text-align: center;
}

.contact-info {
    margin-top: 20px;
}

.tel-font {
    width: 80px; /* Adjust the width as needed */
    text-align: right;
}

.form-control {
    width: calc(100% - 80px); /* Adjust based on the width of tel-font */
}