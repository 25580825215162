.videoView {
  margin-top: 2.7rem;
  margin-bottom: 4rem;
}
h5{
  line-height: 2;
}

.blockImages {
  background-color: #ABCD;
  align-items: center;
  text-align: center;
  margin: 2rem 0 2rem 0;
  /* height: 50px; */
  border-radius: 7px;
}

.common_Text {
  font-size: 18px;
  color: white;
  padding: 7px;
  font-weight: bold;
}

.col:hover {
  background-color: #ABCD;
}

.imageGallery {
  /* position: 'absolute'; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: '50%';
  width: '50%';
  objectFit: 'cover';
}

.cardimage {
  margin: 20px 10px 0 0;
  width: '15rem';
  height: '15rem'
}

.card {
  border: none;
  /* --bs-card-border-width: var(--bs-border-width);
    --bs-card-border-color: var(--bs-border-color-translucent);  */
}

.container {
  padding: 15px;
}

ul.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.images>li {
  position: relative;
  padding-top: 66%;
}

ul.images>li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 820px) {
  .videoView {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    align-items: center;
  }
}

@media (max-width: 567px) {
  ul.images {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .blockImages {
    margin: 1rem 0 1rem 0;
  }

  .borderbottom {
    border-bottom: 1px solid #ABCD;
  }

  .mobileview {
    width: 200px;
    max-width: 100%;
    /* height: 50px; */
    max-height: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
@media (max-width: 1366px) {
  .mobileview {
    width: 200px;
    max-width: 100%;
    /* height: 50px; */
    max-height: 100%;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}