.AppName {
    font-weight: bold;
    font-size: 32px;
  }
.header_shadow {
  box-shadow:0px -0.9px 5px 1.1px grey;
}
.nav-link {
  position: relative;
  font-size: 16px;
}
.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
  padding: 0 !important;
  /* padding: 0 0 5px 5px ; */
}
nav.navbar.navbar-expand-lg.bg-body-tertiary.fixed-top.header_shadow {
  background-color: #ABCD !important;
  padding: 15px
}
.SubHeading::after {
  position: absolute;
  border-bottom: 2px solid black;
  content: "";
  overflow: hidden; 
  left: 5px;
  bottom: 5px;
}
.SubHeading:hover::after {
  width: 80%;
}
a.link-LangLink {
  padding: 0;
  margin: 0;
  border: none;
  text-decoration: none;
}
.dah{
  width: 50px;
  height: 50px;
  border: 1px solid gold;
  border-radius: 7px;
  padding: 2px;
  /* margin: 1rem; */
}






ul {
  padding: 0;
  list-style: none;
  /* background: #f2f2f2; */
  /* width: 500px; */
}

ul li {
  display: block;
  position: relative;
  line-height: 21px;
  text-align: left;
}
ul li a:hover {
  /* width: 250px; */
  color: #fff;
  /* background: #939393; */
}
ul li ul.dropdown {
  min-width: 100%;
  /* Set width of the dropdown */
  background: #f2f2f2;
  display: none;
  position: absolute;
  z-index: 999;
  /* left: 0; */
}
ul li:hover ul.dropdown {
  display: block;
  width: 150px;
  /* Display the dropdown */
}