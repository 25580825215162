.mg-top {
    margin-top: 1rem;
}
.bg-Image {
    background-image: url("../assets/product-banner.jpg");
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    background-position: center;
}

.Block-Banner {
    display: flex;
    align-items: center;
    padding: 0;
    /* margin: 0rem 6rem; */
}

.box-wr {
    backdrop-filter: blur(4.7105798721px) !important;
    background-color: rgba(255, 255, 255, .6) !important;
    background: 0 0;
    min-height: 41px;
    padding: 2.5rem 4rem 2.5rem 4rem;
}

.Search_common {
    width: auto;
    max-width: 100%;
    margin-bottom: 2rem;
    background-color: white;
    color: black;
}

.parent_class_1 {
    margin-top: 2rem;

}

.parent_class_1 .dropdown {
    width: 100%;
    display: grid;
}

.parent_class_1 .dropdown-toggle::after {
    margin-left: 13.1em;
    /* padding: 0.7rem; */
}

.parent_class_1 .dropdown-toggle {
    padding: 0.7rem;
}
.howerEffect {
    position: absolute;
    width: 100%;
    background: #0d0f0e7a;
    height: 100%;
  }

.parent_class_1 .col-md-3:last-child .dropdown-toggle::after {
    content: none;
}
.menu_colors {
    background-color: white;
    color: black;
}
.btn:hover {
    color: black;
    background-color: white;
    /* border-color: var(--bs-btn-hover-border-color); */
}
.btn.show, .btn:first-child:active {
    color: black;
    background-color: white;
    border-color:grey;
}
.button_dropdown .btn.show, .btn:last-child:active {
    color: white;
    background-color: #1e3d59;
    border-color: grey;
    font-weight: bold;
    letter-spacing: 1px;
}
.button_dropdown .btn:hover .btn-primary {
    color: white;
    background-color: #1e3d59;
    border-color: grey;
    font-weight: bold;
    letter-spacing: 1px;
}
.button_dropdown .btn-primary {
    color: white;
    background-color: #1e3d59;
    border-color: grey;
    font-weight: bold;
    letter-spacing: 1px;
}
.card_block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }


@media (max-width: 576px) {
    .bg-Image {
        background-image: url("../assets/product-banner.jpg");
        width: 100%;
        height: 340px;
    }

    .box-wr {
        padding: 1.5rem 1.4rem 1.5rem 1.4rem;
    }

    .Title_banner {
        font-size: 18px;
    }

    .Subtitle_banner {
        font-size: 12px;
    }

    .Search_common {
        width: auto;
        /* margin-top: 2rem; */
        margin-bottom: 0.7rem;
    }

    .parent_class_1 {
        margin-top: 1rem;
    }

    .dropdown {
        display: flex;
        justify-content: center;
        text-align: center;
    }
    /* .container-fluid.parent_class_2 {
        margin-top: 2rem !important;
    }

    .parent_class_2 {
        margin-top: 2rem !important;
    } */

    .parent_class_1 .dropdown-toggle {
        margin-top: 0.5rem;

    }

}

@media (max-width: 769px) {
    .parent_class_1 .dropdown-toggle::after {
        /* margin-top: 1rem; */
        margin-left: 4.1em;
    }

}

@media (max-width: 820px) {
    .parent_class_1 .dropdown-toggle::after {
        /* margin-top: 1rem; */
        margin-left: 4.1em;
    }
}

@media (max-width: 1024px) {
    .parent_class_1 .dropdown-toggle::after {
        /* margin-top: 1rem; */
        margin-left: 0.1em;

    }

    .parent_class_1 .dropdown-toggle {
        /* margin-top: 1rem; */
        padding: 0.4rem;
    }
}

@media (max-width: 1280px) {
    .parent_class_1 .dropdown-toggle::after {
        /* margin-top: 1rem; */
        margin-left: 9.1em;
    }
}