.carousel {
    margin-bottom: 4rem;
  } 
  .carousel-inner{
    height: 100vh;
    display: flex;
  }
  .carousel-caption {
   transform: translateY(-30vh);
  }
  .image {
    width: 100%;
    height: 100%;
    background-position: center;
  }
 

  @media (max-width: 820px) {
    .carousel-caption {
        transform: translateY(-40vh);
       }
  }
  @media (max-width: 912px) {
    .carousel-caption {
        transform: translateY(-40vh);
       }
  }
  @media (max-width: 540px) {
    .carousel-caption {
        transform: translateY(-50vh);
       }
  }

  @media (max-width: 576px) {
    .carousel-control-next-icon, .carousel-control-prev-icon {
        display: none !important;
    }
    .carousel-inner{
      height: 50vh;
        display: flex;
      }
      .carousel-caption {
        transform: translateY(-7vh);
       }
       .carousel {
        margin-bottom: 1.5rem;
      } 
  }