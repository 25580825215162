.card {
    margin-top: 1rem;
}
.col:hover {
    background-color: transparent !important;
}
.servies_block_1{
    position: relative;
    text-align: center;
    top: -35px;
}
.services_sub{
    position: absolute;
    left: 20%;
    transform: translate(-20%, -20%);
    background-color: rgba(255, 0, 0, 0.4);
    width:100%;
}
.sub {
     position: relative;
     padding: 3.5px;
}
.sub_1{
    position: absolute;
    left: 20%;
    transform: translate(-20%, -20%);
    background-color: rgba(29, 27, 39, 0.603);
    width:100%;
    height: 100%;
    transition: .5s ease;
    opacity:0;
    top: 7px;
}
.sub:hover .sub_1{
    opacity: 1;
  }

.block_text {
    color: white;
    padding-top: 5px;
}
/* .img-fluid  {
    height: 220px;
} */

@media (max-width: 820px) { 
    .servies_block_1{
        position: relative;
        text-align: center;
        top: -25px;
    }
    .sub {
        position: relative;
        padding: 1px;
   }
    .block_text {
        color: white;
        font-size: 12px;
        padding-top: 7px;
    }
}

/* @media (max-width: 820px) { 
    .servies_block_1{
        position: relative;
        text-align: center;
        top: -36px;
    }
} */