.mg-top {
  margin-top: 1rem;
}

.card {
  position: relative;
}

.titletext {
  padding: 0.5rem 0 0.5rem 1rem;
}

.card_block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008bba34;
}

.card:hover .overlay {
  opacity: 1;
}
.img-fluid{
  border-radius: 5px;
}

.block {
  position: absolute;
  top: 28%;
  left: 22%;
  -webkit-transform: translate(-14%, 0%);
  -ms-transform: translate(-14%, 0%);
  transform: translate(-14%, 0%);
}

.text {
  color: white;
  text-align: center;
  font-size: 22px;
}

.text_1 {
  color: white;
  text-align: center;
  margin-top: 2rem;
}

@media (max-width: 1024px) {
  .block {
    top: 24%;
  }

  .text {
    font-size: 18px;
  }
}

@media (max-width: 820px) {
  .block {
    top: 15%;
  }

  .text {
    font-size: 12px;
  }

  .text_1 {
    margin-top: 1rem;
  }
}

@media (max-width: 540px) {
  .block {
    top: 28%;
  }

  .text_1 {
    font-size: 12px;
  }
}